import { useState } from 'react';
import data from './data'
import Phrase from './Components/Phrase.js';

function App() {
  const [phrases] = useState(data)
  const [player] = useState(new Audio())
  const [volume, setVolume] = useState(1)
  const [currentPlaying, setCurrentPlaying] = useState();

  const updatePlayerVolume = (e) => {
    const vol = e.target.valueAsNumber;
    setVolume(vol)

    player.volume = volume
  }

  return (
    <div className="App">
      <h1>Heyitzray Soundboard</h1>
      <div className={'SoundsCon'}>
        <div className={'Sounds'}>
          {
            phrases.map((phrase) => {
              return <Phrase phrase={phrase} player={player} currentPlaying={currentPlaying} setCurrentPlaying={setCurrentPlaying} key={phrase.title}></Phrase>
            })
          }
        </div>
      </div>

      <div id="volumeBar">
        <p>Volume:</p>
        <input type="range" min={0} max={1} step={0.02} value={volume} onChange={event => { updatePlayerVolume(event) }} />
      </div>
    </div >
  );
}

export default App;
