import React, { useState } from 'react'
import '../index.css'

function Phrase({ phrase, player, currentPlaying, setCurrentPlaying }) {
    const [index, setIndex] = useState(0)
    const [playedIndex, setPlayedIndex] = useState(0)

    const handleAudioPlay = (files) => {
        setCurrentPlaying(phrase.title)
        // const random = Math.floor(Math.random() * phrase.files.length);

        player.src = files[index]
        player.play()

        player.onended=function()
        {
            setCurrentPlaying()
        }

        setIndex(index + 1)
        setPlayedIndex(index)

        if (index >= files.length - 1) {
            setIndex(0)
        }
    }

    const displayIndexDots = () => {
        if (phrase.files.length <= 1) {
            return;
        }

        let dots = [];
        for (let i = 0; i < phrase.files.length; i++) {
            dots.push(
                <span className={i !== playedIndex ? "inactiveDot" : "activeDot"} key={phrase.title + '-dot' + i}>
                    &#183;
                </span>
            );
        }

        return (
            <div className="dotsRow">
                <span className="dotsContainer">
                    {dots}
                </span>
            </div>
        )
    }

    return (
        <div className={'SoundsBtn'} onClick={() => handleAudioPlay(phrase.files)}>
            {(currentPlaying === phrase.title) && <img className="playingIndicator" src="/ray.png" width="30px" alt="ray face"></img>}
            <div className='soundContainer'>
                <p>{phrase.title}</p>
                {displayIndexDots()}
            </div>
        </div>
    )
}

export default Phrase